
import Vue from "vue";

export default Vue.extend({
  name: "Privacy",
  data: () => ({
    itemsPrivacyAddress: [
      {
        description: "Firmenname",
        name: "TÜV | DEKRA arge tp 21 GmbH"
      }, {
        description: "Adresse",
        name: "Wintergartenstraße 4, 01307 Dresden"
      }, {
        description: "Geschäftsführer",
        name: "Dipl.-Ing. Mathias Rüdel"
      }, {
        description: "Telefon",
        name: "+49 351 20789-0"
      }, {
        description: "Fax",
        name: "+49 351 20789-20"
      }, {
        description: "E-Mail",
        name: "datenschutz@argetp21.de"
      }
    ],
    itemsDataCollection: [
      {
        item: "Informationen über den Browsertyp und die verwendete Version;",
      }, {
        item: "das Betriebssystem des Abrufgerätes;",
      }, {
        item: "Hostname des zugreifenden Rechners;",
      }, {
        item: "die IP-Adresse des Abrufgerätes;",
      }, {
        item: "Datum und Uhrzeit des Zugriffs;",
      }, {
        item: "Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer Internetseite aufgerufen wurden;",
      }, {
        item: "Websites, von denen das System des Nutzers auf unsere Internetseite gelangte;",
      }, {
        item: "Meldung, ob der Abruf erfolgreich war;",
      }, {
        item: "übertragene Datenmenge.",
      },
    ],
  })
});
